import { $t } from '@/i18n/index'

export const transactionFilterSaveType = {
  TRANSFER: 'TRANSFER',
  TRADE: 'TRADE',
  GAIN_LOSS: 'GAIN_LOSS',
  RECONCILIATION_PENDING: 'RECONCILIATION_PENDING',
  RECONCILIATION_PROCESSED: 'RECONCILIATION_PROCESSED'
}

export const assetCategoryType = [
  {
    value: 'CRYPTO_CURRENCY',
    label: $t('valuation.assets.cryptocurrency')
  },
  {
    value: 'CRYPTO_STABLE_COIN',
    label: $t('valuation.assets.stablecoin')
  },
  {
    value: 'DERIVATIVE',
    label: $t('valuation.assets.derivative')
  },
  {
    value: 'CRYPTO_TOKEN',
    label: $t('project.costBasis.token')
  },
  {
    value: 'CRYPTO_NFT',
    label: $t('common.spam')
  }
]

export const automationExecuteTips: any = {
  transfer: [
    {
      title: $t('automation.forNewTransfers'),
      ol: [$t('automation.forNewTransfersInfo')]
    },
    {
      title: $t('automation.forExistingTransfers'),
      ol: [
        $t('automation.existingRecordsInfoForAdjustments'),
        $t('automation.existingRecordsInfoForAction'),
        $t('automation.existingRecordsInfoNotLongerMatched'),
        $t('automation.existingRecordsInfoIsMatched')
      ]
    }
  ],
  trade: [
    {
      title: $t('automation.forEewTradeRecords'),
      ol: [$t('automation.forEewRecordsInfo')]
    },
    {
      title: $t('automation.forExistingTradeRecords'),
      ol: [
        $t('automation.existingRecordsInfoForAdjustments'),
        $t('automation.existingRecordsInfoForAction'),
        $t('automation.existingRecordsInfoNotLongerMatched'),
        $t('automation.existingRecordsInfoAreMatched')
      ]
    }
  ],
  gainLoss: [
    {
      title: $t('automation.forNewGainLossRecords'),
      ol: [$t('automation.forEewRecordsInfo')]
    },
    {
      title: $t('automation.forExistingGainLossRecords'),
      ol: [
        $t('automation.existingRecordsInfoForAdjustments'),
        $t('automation.existingRecordsInfoForAction'),
        $t('automation.existingRecordsInfoNotLongerMatched'),
        $t('automation.existingRecordsInfoAreMatched')
      ]
    }
  ],
  businessData: [
    {
      title: $t('automation.forNewBusinessData'),
      ol: [$t('automation.forEewRecordsInfo')]
    },
    {
      title: $t('automation.forExistingBusinessData'),
      ol: [
        $t('automation.existingRecordsInfoForAdjustments'),
        $t('automation.existingRecordsInfoForAction'),
        $t('automation.existingRecordsInfoNotLongerMatched'),
        $t('automation.existingRecordsInfoAreMatched')
      ]
    }
  ],
  businessDataForName: [
    {
      title: $t('automation.forNewBusinessData'),
      ol: [$t('automation.forNewBusinessDataInfo')]
    },
    {
      title: $t('automation.forExistingBusinessData'),
      ol: [
        $t('automation.ruleHasNoAdjustmentsInfo'),
        $t('automation.ruleChangesTheActionInfo'),
        $t('automation.ruleChangesTheConditionInfo')
      ],
      ul: [$t('automation.previouslyMatchedRecords'), $t('automation.newlyMatchedRecordsInfo')]
    }
  ],
  businessDataToValuation: [
    {
      title: $t('automation.forNewBusinessData'),
      ol: [$t('automation.forEewRecordsInfo')]
    },
    {
      title: $t('automation.forExistingBusinessData'),
      ol: [
        $t('automation.existingRecordsInfoForValuationAdjustments'),
        $t('automation.existingRecordsInfoForValuationAction'),
        $t('automation.existingRecordsInfoNotLongerMatchedValuation'),
        $t('automation.existingRecordsInfoAreMatchedValuation')
      ]
    }
  ],
  businessDataToValuationForName: [
    {
      title: $t('automation.forNewBusinessData'),
      ol: [$t('automation.forNewBusinessDataInfo')]
    },
    {
      title: $t('automation.forExistingBusinessData'),
      ol: [
        $t('automation.ruleHasNoAdjustmentsValuationInfo'),
        $t('automation.ruleChangesTheActionValuationInfo'),
        $t('automation.ruleChangesTheConditionInfo')
      ],
      ul: [$t('automation.previouslyMatchedValuationRecords'), $t('automation.newlyMatchedRecordsValuationInfo')]
    }
  ],
  counterpartyTransformer: [
    {
      title: $t('automation.forAllTransfers'),
      ol: [$t('automation.manuallySetCounterpartiesInfo'), $t('automation.forNewTransfersInfo')]
    }
  ],
  valuationToJournal: [
    {
      title: $t('automation.forNewRecords'),
      ol: [$t('automation.forNewTransfersInfo')]
    },
    {
      title: $t('automation.forExistingRecords'),
      ol: [
        $t('automation.existingRecordsInfoForAdjustments'),
        $t('automation.existingRecordsInfoForAction'),
        $t('automation.existingRecordsInfoNotLongerMatched'),
        $t('automation.existingRecordsInfoIsMatched')
      ]
    }
  ]
}
